import {
  Box,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  LinearProgress,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import axiosInstance from "src/utils/axiosInstance";
import { PAGE_LIMIT } from "src/utils/constants";
import SearchIcon from "@mui/icons-material/Search";
import { Notify } from "notiflix";
import { AddTwoTone, RemoveTwoTone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

export default function GroupMembers({ groupInfo, setGroupInfo }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const [filterObj, setFilterObj] = useState(null);
  const [loading, setLoading] = useState(false);

  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(parseInt(pageParam) || 1);
  const [searchString, setSearchString] = useState("");
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);

  const handlePageChange = (event, value) => {
    setPage(value);
    setSearchParams({ page: value });
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const reqParams = {
          params: { limit: PAGE_LIMIT, skip: (page - 1) * PAGE_LIMIT },
        };

        if (filterObj) {
          reqParams.params = { ...reqParams.params, ...filterObj };
        }

        // const resp = await axiosInstance.get("/employees", reqParams);
        // if (resp?.status === 200) {
        //   setUsers(resp?.data);
        // }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured"
        );
      }
    })();
  }, [page, filterObj]);

  useEffect(() => {
    if (groupInfo && groupInfo?.organisation) {
      try {
        if (departments.length > 0 && designations.length > 0) return;
        (async () => {
          const tagResp = await axiosInstance.get("/tags", {
            params: {
              organisationId: groupInfo?.organisation?._id,
            },
          });
          if (tagResp.status === 200 && tagResp.data?.length > 0) {
            const _departments = tagResp?.data?.find(
              (item) => item?.type === "departments"
            )?.tags;
            setDepartments(_departments || []);

            const _designations = tagResp?.data?.find(
              (item) => item?.type === "designations"
            )?.tags;
            setDesignations(_designations || []);
          }
        })();
      } catch (error) {
        console.log(error);
      }
    }
  }, [groupInfo]);

  const AddToGroupButton = ({ item }) => {
    const [isWait, setIsWait] = React.useState(false);
    const [exists, setExists] = React.useState(false);

    useEffect(() => {
      setExists(groupInfo?.members?.find((user) => user === item?._id));
    }, [groupInfo]);

    const handleAddToGroup = async (action, userId) => {
      try {
        setIsWait(true);
        const resp = await axiosInstance.put(
          `/groups/${groupInfo?._id}/members`,
          {
            action,
            userId,
          }
        );
        if (resp?.status === 200) {
          setGroupInfo(resp?.data);
        }
        setIsWait(false);
      } catch (error) {
        setIsWait(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured"
        );
      }
    };

    return (
      <LoadingButton
        variant="text"
        loading={isWait}
        size="small"
        color={exists ? "error" : "primary"}
        startIcon={exists ? <RemoveTwoTone /> : <AddTwoTone />}
        onClick={() => handleAddToGroup(exists ? "remove" : "add", item?._id)}
      >
        {exists ? "Remove" : "Add"}
      </LoadingButton>
    );
  };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Box
          border={1}
          m={2}
          borderRadius={1}
          borderColor={"primary.main"}
          display={"flex"}
          minWidth={500}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search here..."
            inputProps={{ "aria-label": "search here" }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                e.preventDefault();
                if (e.target.value.length) {
                  setFilterObj({ ...filterObj, search: searchString });
                } else {
                  delete filterObj.search;
                  setFilterObj({ ...filterObj, search: null });
                }
              }
            }}
            onChange={(e) => {
              if (e.target.value?.length > 0) {
                setSearchString(e.target.value);
              } else {
                console.log(e.target.value?.length);
                if (filterObj?.search) {
                  delete filterObj.search;
                  setFilterObj({ ...filterObj, search: null });
                }
              }
            }}
          />
          <IconButton
            type="button"
            sx={{
              bgcolor: "primary.main",
              color: "white",
            }}
            onClick={() => {
              setFilterObj({
                ...filterObj,
                search: searchString,
              });
            }}
          >
            <SearchIcon />
          </IconButton>
        </Box>
        <FormControl size="small" fullWidth>
          <InputLabel>Designation</InputLabel>
          <Select
            fullWidth
            label="Designation"
            name="designation"
            value={filterObj?.designation || ""}
            onChange={(e) => {
              if (e.target.value === "") {
                delete filterObj.designation;
                setFilterObj({ ...filterObj });
                return;
              }
              setFilterObj({
                ...filterObj,
                designation: e.target.value,
              });
            }}
          >
            <MenuItem value="">All</MenuItem>
            {designations?.map((item, idx) => (
              <MenuItem key={idx} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ mx: 2 }} fullWidth>
          <InputLabel>Department</InputLabel>
          <Select
            fullWidth
            label="Department"
            name="department"
            value={filterObj?.department || ""}
            onChange={(e) => {
              if (e.target.value === "") {
                delete filterObj.department;
                setFilterObj({ ...filterObj });
                return;
              }
              setFilterObj({
                ...filterObj,
                department: e.target.value,
              });
            }}
          >
            <MenuItem value="">All</MenuItem>
            {departments?.map((item, idx) => (
              <MenuItem key={idx} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>

      <Box sx={{ mb: 4 }}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Full Name</TableCell>
                <TableCell> Email</TableCell>
                <TableCell>Designation</TableCell>
                <TableCell> Account Status </TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <LinearProgress sx={{ m: 3 }} />
              ) : (
                <>
                  {users?.data?.map((item) => (
                    <TableRow
                      key={item?._id}
                      sx={{
                        color: item?.isLocked && "red",
                        textDecoration: item?.isLocked && "line-through",
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        component={Link}
                        to={`/dashboard/users/${item?.user?._id}`}
                        sx={{ textDecoration: "none", color: "primary.main" }}
                      >
                        {item?.firstName + " " + (item?.lastName || "")}
                        <br />
                        <small style={{ color: "grey" }}>
                          {item?.employeeId || "-"}
                        </small>
                      </TableCell>
                      <TableCell>{item?.email}</TableCell>
                      <TableCell>
                        {item?.designation || "-"}
                        <br />
                        <small style={{ color: "grey" }}>
                          {item?.department || ""}
                        </small>
                      </TableCell>{" "}
                      <TableCell>
                        <Typography
                          color={item?.isLocked ? "red" : "green"}
                          variant="subtitle2"
                        >
                          {item?.isLocked ? "Deactivated" : "Active"}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <AddToGroupButton item={item} />
                      </TableCell>
                    </TableRow>
                  ))}
                  {users?.data?.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No users found
                      </TableCell>
                    </TableRow>
                  )}
                </>
              )}
            </TableBody>
          </Table>

          {!loading && users?.data?.length > 0 && (
            <Box sx={{ m: 3, display: "flex", justifyContent: "flex-end" }}>
              <Stack spacing={2}>
                <Pagination
                  count={users?.totalPages}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Stack>
            </Box>
          )}
        </TableContainer>
      </Box>
    </>
  );
}
