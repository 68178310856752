import {
  Box,
  Card,
  Typography,
  Button,
  CardActionArea,
  CardMedia,
  Divider,
  useTheme,
  IconButton,
  Stack,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Grid,
} from "@mui/material";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { Link, useParams } from "react-router-dom";

import Label from "src/components/Label";
import { CLEAN_EXIT_STATUS } from "src/utils/constants";
import { useContext, useEffect, useState } from "react";
import Notiflix, { Notify } from "notiflix";
import axiosInstance from "src/utils/axiosInstance";
import {
  CloudDownloadTwoTone,
  Grading,
  HourglassBottom,
} from "@mui/icons-material";
import { fDate, fTime } from "src/utils/date-fns";
import SuspenseLoader from "src/components/SuspenseLoader";
import CleanExitComment from "./components/CleanExitComment";
import { LoadingButton } from "@mui/lab";
import { GlobalContext } from "src/contexts/GlobalContext";
import EmptyHere from "src/components/EmptyHere";

function CleanExit() {
  const { currentUser } = useContext(GlobalContext);
  const theme = useTheme();
  const { cleanExitId } = useParams();
  const [loading, setLoading] = useState(true);
  const [application, setApplication] = useState(null);
  const [isWait, setIsWait] = useState(false);

  useEffect(() => {
    if (!cleanExitId) {
      Notify.failure("Invalid application id");
      return;
    }
    (async () => {
      setLoading(true);
      const resp = await axiosInstance.get(`/cleanexits/${cleanExitId}`);
      if (resp?.status === 200) {
        setApplication(resp?.data);
      }
      setLoading(false);
    })();
  }, []);

  const handleInReviewButton = async () => {
    try {
      setIsWait(true);

      const updateResp = await axiosInstance.put(
        `/cleanexits/${cleanExitId}${
          ["admin", "triagger"]?.includes(currentUser?.accountType)
            ? `?organisation=${application?.organisation?._id}`
            : ""
        }`,
        {
          status: "in-review",
        }
      );
      if (updateResp?.status === 200) {
        const resp = await axiosInstance.post(
          `/cleanexits/${cleanExitId}/logs`,
          {
            title:
              "<p>Clean exit Application initiated and is <b style='color:orange'>In Review</b>.</p>",
          }
        );
        if (resp?.status === 201) {
          Notify.success("Marked as In Review");
          setIsWait(false);
          window.location.replace(
            "/dashboard/cleanexits/" + resp.data.cleanExitId
          );
        }
      }
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  return loading ? (
    <SuspenseLoader />
  ) : !application && application === null ? (
    <EmptyHere message="No EthixFirst Application found" />
  ) : (
    <Box my={2}>
      <Card>
        <Box
          p={2.5}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="h3">
              EthixFirst Application ({cleanExitId})
            </Typography>
            <Typography variant="subtitle2">
              For{" "}
              <Link
                to={`/dashboard/users/${application?.user?._id}`}
                target="_blank"
                style={{
                  textDecoration: "none",
                }}
              >
                <b>
                  {application?.user?.firstName}{" "}
                  {application?.user?.lastName || ""}
                </b>{" "}
              </Link>
              in{" "}
              <Link
                to={`/dashboard/organisations/${application?.organisation?._id}`}
                target="_blank"
                style={{
                  textDecoration: "none",
                }}
              >
                <b>{application?.organisation?.title}</b>
              </Link>
            </Typography>
          </Box>
          <Box>
            <Label color={CLEAN_EXIT_STATUS[application?.status]?.color}>
              <h3>
                {CLEAN_EXIT_STATUS[application?.status]?.label?.toUpperCase()}
              </h3>
            </Label>
          </Box>
        </Box>
        <Divider />
        <CardContent>
          <Timeline>
            {application &&
              application?.logs?.map((item, idx) => (
                <TimelineItem
                  key={idx}
                  sx={{
                    p: 0,
                  }}
                >
                  <TimelineOppositeContent
                    sx={{
                      width: "200px",
                      flex: "none",
                      whiteSpace: "nowrap",
                    }}
                    color="text.secondary"
                  >
                    <Typography>
                      <strong>{fDate(item?.createdAt)}</strong>
                    </Typography>
                    {fTime(item?.createdAt)}
                  </TimelineOppositeContent>
                  <TimelineSeparator
                    sx={{
                      position: "relative",
                      mx: 2,
                    }}
                  >
                    <TimelineDot
                      sx={{
                        marginTop: 0,
                        top: theme.spacing(1.2),
                      }}
                      variant="outlined"
                      color="primary"
                    />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent
                    sx={{
                      pb: 4,
                    }}
                  >
                    <Stack
                      spacing={2}
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      {item?.title && (
                        <Typography variant="h4">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item?.title,
                            }}
                          />
                        </Typography>
                      )}

                      {item?.by && (
                        <>
                          <Typography
                            component={Link}
                            to={`/dashboard/users/${item?.by?._id}`}
                            style={{
                              textDecoration: "none",
                            }}
                          >
                            by-{" "}
                            <b>
                              {item?.by?.firstName} {item?.by?.lastName || ""}
                            </b>
                          </Typography>
                        </>
                      )}
                    </Stack>

                    {item?.description && (
                      <Typography variant="body1" color="text.secondary">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item?.description,
                          }}
                        />
                      </Typography>
                    )}
                    {item?.message && (
                      <Typography variant="subtitle1">
                        Comment: {item?.message}
                      </Typography>
                    )}

                    {item?.linktext && (
                      <Button
                        size="small"
                        sx={{ my: 2 }}
                        variant="contained"
                        component={item?.link ? Link : "span"}
                        to={item?.link ? item?.link : ""}
                        color={item?.linkcolor || "warning"}
                      >
                        {item?.linktext}
                      </Button>
                    )}
                    {item?.media && item?.media?.length > 0 && (
                      <Box display="flex" mt={2} alignItems="flex-start">
                        {item?.media?.map((media, indx) => (
                          <Card
                            key={indx}
                            sx={{
                              mr: 2,
                              width: media?.type !== "image" && 64,
                              height: media?.type !== "image" && 64,
                            }}
                          >
                            <CardActionArea
                              component="a"
                              href={
                                process.env.REACT_APP_DO_SPACE_URL +
                                media?.url +
                                `?${Math.random()}`
                              }
                              target="_blank"
                            >
                              {media?.type?.includes("image") ? (
                                <CardMedia
                                  component="img"
                                  height="64"
                                  image={
                                    process.env.REACT_APP_DO_SPACE_URL +
                                    media?.url +
                                    `?${Math.random()}`
                                  }
                                  alt={media?.type}
                                />
                              ) : (
                                <IconButton
                                  size="small"
                                  color="primary"
                                  variant="contained"
                                  sx={{
                                    height: 64,
                                    width: 64,
                                  }}
                                >
                                  <CloudDownloadTwoTone />
                                </IconButton>
                              )}
                            </CardActionArea>
                          </Card>
                        ))}
                      </Box>
                    )}
                  </TimelineContent>
                </TimelineItem>
              ))}
            {application?.status === "open" && (
              <>
                {currentUser?.accountType === "admin" ? (
                  <LoadingButton
                    variant="outlined"
                    color="warning"
                    loading={isWait}
                    onClick={() => {
                      Notiflix.Confirm.show(
                        "Confirmation",
                        "Are you sure you want to continue?",
                        "Yes",
                        "Cancel",
                        () => handleInReviewButton(),
                        () => {},
                        {
                          width: "500px",
                          messageMaxLength: 1000,
                        }
                      );
                    }}
                    startIcon={<Grading />}
                  >
                    Initiate EthixFirst Process (mark as In Review)
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    variant="outlined"
                    color="warning"
                    startIcon={<HourglassBottom />}
                    sx={{
                      cursor: "default",
                    }}
                  >
                    Waiting for Admin to initiate EthixFirst Interview Process
                  </LoadingButton>
                )}
              </>
            )}
            {application?.status === "in-review" && (
              <CleanExitComment cleanExitId={cleanExitId} />
            )}
            {!["open", "in-review"].includes(application?.status) && (
              <Label
                color={CLEAN_EXIT_STATUS[application?.status]?.color}
                height="100%"
                width="100%"
              >
                <Grid container>
                  <Grid item xs={4}>
                    <Stack
                      direction="column"
                      mt={2}
                      alignItems="center"
                      spacing={2}
                    >
                      {(() => {
                        const Icon =
                          CLEAN_EXIT_STATUS[application?.status || "closed"];
                        return Icon ? (
                          <Icon.icon sx={{ fontSize: "3rem" }} />
                        ) : (
                          ""
                        );
                      })()}

                      <Label
                        color={CLEAN_EXIT_STATUS[application?.status]?.color}
                      >
                        <h1>{application?.status?.toUpperCase()}</h1>
                      </Label>
                    </Stack>
                  </Grid>
                  <Grid item xs={8}>
                    <List
                      disablePadding
                      sx={{
                        mt: 2,
                      }}
                    >
                      {CLEAN_EXIT_STATUS[application?.status]?.logs?.map(
                        (log, indx) => (
                          <ListItem key={indx}>
                            <ListItemText
                              primary={
                                <b>
                                  {indx + 1}. {log}
                                </b>
                              }
                              primaryTypographyProps={{
                                color: "textGrey",
                              }}
                            />
                          </ListItem>
                        )
                      )}
                    </List>
                  </Grid>
                </Grid>
              </Label>
            )}
          </Timeline>
        </CardContent>
      </Card>
    </Box>
  );
}

export default CleanExit;
