import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Stack,
  Typography,
  capitalize,
  styled,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import SuspenseLoader from "src/components/SuspenseLoader";
import axiosInstance from "src/utils/axiosInstance";
import { fDateTimeSuffix } from "src/utils/date-fns";
import ScoreBar from "../common/ScoreBar";
import { MAX_SCORE } from "src/utils/constants";
import QuestionInputField from "../common/QuestionInputField";
import {
  CalculateOutlined,
  CheckCircleOutline,
  PrintOutlined,
  TimerOutlined,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { ReactPrint } from "src/components/ReactToPrint";
import { calculateFinalScore, calculateTimeRemain } from "src/utils/helpers";
import Label from "src/components/Label";
import CertificationCard from "./components/CertificationCard";
import TitleSubtitleCard from "src/components/Common/TitleSubtitleCard";
import { GlobalContext } from "src/contexts/GlobalContext";

const RootWrapper = styled(Box)(
  () => `
    flex: 1;
    z-index: 99;
    position: sticky;
    top: 0;
    width: 100%;
`
);

export default function RatingFormDetails() {
  const { currentUser } = useContext(GlobalContext);
  const { ratingId } = useParams();
  const [form, setForm] = useState(null);
  const [loading, setLoading] = useState(true);
  const [employee, setEmployee] = useState(null);
  const [formInput, setFormInput] = useState({});
  const [info, setInfo] = useState(null);

  const handleFormInput = (e) => {
    setFormInput({ ...formInput, [e.target.name]: e.target.value });
  };

  const handleGetForm = async (ratingId) => {
    try {
      setLoading(true);
      const resp = await axiosInstance.get(`/ratings/${ratingId}`);
      if (resp?.status === 200) {
        const { form, user, ...rest } = resp?.data;
        setForm(form ? JSON.parse(form.replace(/(\r\n|\n|\r)/gm, "")) : {});
        setEmployee(user);
        setInfo(rest);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );

      return null;
    }
  };

  useEffect(() => {
    handleGetForm(ratingId);
  }, [ratingId]);

  const [print, setPrint] = useState(false);
  const handleClickPrint = () => {
    setPrint(true);
    setTimeout(() => {
      setPrint(false);
    }, 1000);
  };

  return loading ? (
    <SuspenseLoader />
  ) : (
    <Fragment>
      {(() => {
        const result = calculateFinalScore(
          {
            score: info?.score,
            weightage: info?.weightage,
            passingPercentage: info?.passingPercentage,
          },
          info?.type
        );
        return (
          <ReactPrint print={print}>
            <Stack spacing={2} my={2} id="rating-section-print">
              <RootWrapper id={"rating-header"}>
                <Card
                  sx={{
                    backgroundColor: "#289BCC",
                    color: "#fff",
                    p:
                      currentUser?.role === "user" && info?.type === "exam"
                        ? 6
                        : 1,
                  }}
                >
                  {" "}
                  <Grid container spacing={2} alignItems="center" p={1}>
                    <Grid item xs={1.5}>
                      <Avatar
                        sx={{ width: "100px", height: "100px", mx: "auto" }}
                        src={
                          process.env.REACT_APP_DO_SPACE_URL +
                          employee?.profileImage +
                          `?${Math.random()}`
                        }
                      >
                        {employee?.firstName[0]}
                      </Avatar>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="h2" component="div">
                        {form?.title || ""}
                      </Typography>
                      <Typography variant="body1">
                        {form?.description || ""}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2.5}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      {!(
                        currentUser?.role === "user" && info?.type !== "exam"
                      ) &&
                        info?.weightage > 0 && (
                          <Card
                            sx={{
                              width: "200px",
                              height: "100px",
                              textAlign: "center",
                              ml: 2,
                            }}
                          >
                            <Typography fontSize={"3rem"} component={"span"}>
                              <b>
                                {result?.score || 0}
                                {info?.type === "exam" && "%"}
                              </b>
                            </Typography>
                            {info?.type === "rating" && <sub>/{MAX_SCORE}</sub>}
                            <Divider />
                            {info?.type === "exam" ? (
                              <Label
                                sx={{ fontSize: "1.2rem" }}
                                color={result?.color || "success"}
                              >
                                <b>{result?.label || "-"}</b>
                              </Label>
                            ) : (
                              <Typography variant="subtitle1">
                                Weightage -{" "}
                                <b>
                                  {info?.score || 0} out of{" "}
                                  {info?.weightage || 0}
                                </b>
                              </Typography>
                            )}
                          </Card>
                        )}
                    </Grid>
                  </Grid>
                  <Divider
                    sx={{
                      my:
                        currentUser?.role === "user" && info?.type === "exam"
                          ? 4
                          : 1,
                    }}
                  />
                  <CardActions>
                    <Stack
                      direction="row"
                      spacing={2}
                      mx={2}
                      sx={{ width: "100%", whiteSpace: "nowrap" }}
                      justifyContent="space-between"
                    >
                      <Stack direction="row" spacing={2} alignItems={"center"}>
                        {form?.type !== "exam" && employee && (
                          <Typography display={"flex"} alignItems={"center"}>
                            User:{" "}
                            <Typography
                              component={Link}
                              variant="h6"
                              to={`/dashboard/users/${employee?._id}`}
                              target="_blank"
                              mx={1}
                              sx={{
                                color: "#fff",
                                textDecoration: "none",
                              }}
                            >
                              <b>
                                {employee?.firstName +
                                  " " +
                                  (employee?.lastName || "")}
                              </b>
                            </Typography>
                          </Typography>
                        )}

                        {info?.organisation && (
                          <Typography display={"flex"} alignItems={"center"}>
                            Organisation:{" "}
                            <Typography
                              variant="h6"
                              mx={1}
                              sx={{
                                color: "#fff",
                              }}
                            >
                              <b>
                                {info?.organisation?.title ||
                                  "Organisation Name"}
                              </b>
                            </Typography>
                          </Typography>
                        )}
                        {info?.submitBy && (
                          <Typography display={"flex"} alignItems={"center"}>
                            Submitted By:{" "}
                            <Typography
                              component={Link}
                              variant="h6"
                              to={`/dashboard/users/${info?.submitBy?._id}`}
                              target="_blank"
                              mx={1}
                              sx={{
                                color: "#fff",
                                textDecoration: "none",
                              }}
                            >
                              <b>
                                {" "}
                                {info?.submitBy?.firstName +
                                  " " +
                                  (info?.submitBy?.lastName || "")}
                              </b>
                            </Typography>
                          </Typography>
                        )}

                        <Typography display={"flex"} alignItems={"center"}>
                          Submitted on:{" "}
                          <Typography
                            variant="h6"
                            target="_blank"
                            mx={1}
                            sx={{
                              color: "#fff",
                              textDecoration: "none",
                            }}
                          >
                            <b>
                              {fDateTimeSuffix(info?.createdAt) ||
                                fDateTimeSuffix(info?.updatedAt) ||
                                ""}
                            </b>
                          </Typography>
                        </Typography>
                      </Stack>
                      {currentUser?.role === "user" && info?.type === "exam" ? (
                        <> </>
                      ) : (
                        <Box>
                          <LoadingButton
                            color="inherit"
                            variant="outlined"
                            startIcon={<PrintOutlined />}
                            id="hide-on-print"
                            onClick={handleClickPrint}
                          >
                            Print
                          </LoadingButton>
                        </Box>
                      )}
                    </Stack>
                  </CardActions>{" "}
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    mx={1}
                    sx={{
                      my:
                        currentUser?.role === "user" && info?.type === "exam"
                          ? 2
                          : 0,
                    }}
                  >
                    {info?.duration && info?.duration > 0 ? (
                      <Button
                        size="sm"
                        sx={{
                          color: "white",
                        }}
                        startIcon={
                          <TimerOutlined color="info" fontSize="small" />
                        }
                      >
                        Time taken: {calculateTimeRemain(info?.duration * 60)}{" "}
                      </Button>
                    ) : (
                      ""
                    )}
                    {info?.type === "exam" &&
                    info?.attempt &&
                    info?.attempt > 0 ? (
                      <Button
                        size="sm"
                        sx={{
                          color: "white",
                        }}
                        startIcon={
                          <CalculateOutlined color="error" fontSize="small" />
                        }
                      >
                        Attempt Number: {info?.attempt}
                      </Button>
                    ) : (
                      ""
                    )}
                    {info?.passingPercentage && info?.passingPercentage > 0 ? (
                      <Button
                        size="sm"
                        sx={{
                          color: "white",
                        }}
                        startIcon={
                          <CheckCircleOutline
                            color="success"
                            fontSize="small"
                          />
                        }
                      >
                        Min Passing Percentage: {info?.passingPercentage}%
                      </Button>
                    ) : (
                      ""
                    )}
                  </Stack>
                </Card>
              </RootWrapper>
              {Object.keys(info?.organisationDetails || {}).length > 0 && (
                <Card>
                  <CardHeader
                    title="Organisation"
                    subheader="This form is submitted as an open submission"
                  />
                  <Divider />
                  <CardContent>
                    <Stack direction="row" spacing={10}>
                      <TitleSubtitleCard
                        title={"Organisation Name"}
                        subtitle={info?.organisationDetails?.title || ""}
                      />
                      <TitleSubtitleCard
                        title={"Website"}
                        subtitle={
                          info?.organisationDetails?.companyWebsite || "-"
                        }
                      />
                      <TitleSubtitleCard
                        title={"Email"}
                        subtitle={
                          info?.organisationDetails?.companyEmail || "-"
                        }
                      />
                      <TitleSubtitleCard
                        title={"Contact"}
                        subtitle={
                          info?.organisationDetails?.companyPhone || "-"
                        }
                      />
                    </Stack>
                    <Stack spacing={2} direction="row">
                      <TitleSubtitleCard
                        title={"Address"}
                        subtitle={
                          info?.organisationDetails?.companyAddress || "-"
                        }
                      />
                    </Stack>
                  </CardContent>
                </Card>
              )}
              {Object.keys(info?.userDetails || {}).length > 0 && (
                <Card>
                  <CardHeader
                    title="Receiver personal details"
                    subheader="This form is submitted as an open submission"
                  />
                  <Divider />

                  <CardContent>
                    <Stack direction="row" spacing={8} alignItems={"center"}>
                      <TitleSubtitleCard
                        title={"Full Name"}
                        subtitle={
                          info?.userDetails?.firstName +
                          " " +
                          (info?.userDetails?.lastName || "")
                        }
                        avatar={
                          <Avatar
                            src={
                              process.env.REACT_APP_DO_SPACE_URL +
                              info?.userDetails?.profileImage +
                              `?${Math.random()}`
                            }
                            alt={capitalize(
                              info?.userDetails?.firstName?.charAt(0)
                            )}
                            sx={{
                              width: { xs: 30, md: 60 },
                              height: { xs: 30, md: 60 },
                            }}
                          />
                        }
                      />
                      <TitleSubtitleCard
                        title={"Email"}
                        subtitle={info?.userDetails?.email || "-"}
                      />
                      <TitleSubtitleCard
                        title={"Contact"}
                        subtitle={info?.userDetails?.phoneNumber || "-"}
                      />
                      <TitleSubtitleCard
                        title={"PAN Number"}
                        subtitle={info?.userDetails?.pan || "-"}
                      />
                      <TitleSubtitleCard
                        title={"Address"}
                        subtitle={info?.userDetails?.address || "-"}
                      />
                    </Stack>
                  </CardContent>
                </Card>
              )}

              {info?.type === "rating" &&
                info?.includeScore &&
                info?.weightage > 0 && (
                  <Box sx={{ my: 2 }} id="hide-on-print">
                    <ScoreBar
                      score={
                        Math.ceil(
                          (info?.score / info?.weightage) * MAX_SCORE
                        ) || MAX_SCORE
                      }
                    />
                  </Box>
                )}

              {info?.certificate?.certificateId &&
                info?.type === "exam" &&
                result?.label === "Pass" && (
                  <div id="hide-on-print">
                    <CertificationCard info={info} />
                  </div>
                )}

              {currentUser?.role === "user" && info?.type === "exam" ? (
                <></>
              ) : (
                <>
                  {form?.formGroups?.map((group) => (
                    <Card key={group?._id}>
                      <CardHeader
                        title={
                          <Typography variant="h3" component="div">
                            {group?.title || ""}
                          </Typography>
                        }
                        subheader={group?.description || ""}
                      />
                      <Divider />
                      <CardContent>
                        <Stack spacing={2}>
                          {group?.questionsGroup?.map((questionGroup) => (
                            <Card key={questionGroup?._id}>
                              <CardHeader
                                title={
                                  <Typography variant="h4" component="div">
                                    {questionGroup?.title || ""}
                                  </Typography>
                                }
                                subheader={questionGroup?.description || ""}
                              />
                              <Divider />
                              <CardContent>
                                <Stack spacing={2}>
                                  {questionGroup?.questions?.map(
                                    (question, index) => (
                                      <Card key={question?._id} elevation={0}>
                                        <CardHeader
                                          title={
                                            <Typography
                                              variant="h5"
                                              component="div"
                                            >
                                              {index + 1}.{" "}
                                              {question?.title || ""}
                                              {question?.required && (
                                                <span
                                                  style={{
                                                    color: "red",
                                                    marginInline: 3,
                                                  }}
                                                >
                                                  *
                                                </span>
                                              )}
                                            </Typography>
                                          }
                                          subheader={
                                            <>
                                              <Typography
                                                variant="subtitle1"
                                                sx={{ mx: 1.5 }}
                                              >
                                                {question?.description || ""}
                                              </Typography>
                                            </>
                                          }
                                        />
                                        <CardContent sx={{ py: 0, mx: 1.5 }}>
                                          <QuestionInputField
                                            question={question}
                                            handleFormInput={handleFormInput}
                                            formInput={formInput}
                                            setFormInput={setFormInput}
                                            disabled={true}
                                          />
                                        </CardContent>
                                      </Card>
                                    )
                                  )}
                                </Stack>
                              </CardContent>
                            </Card>
                          ))}
                        </Stack>
                      </CardContent>
                      {group?.contextualForms?.length > 0 &&
                        group?.contextualForms?.some(
                          (cForm) => cForm?.answer
                        ) && (
                          <Fragment>
                            <Divider sx={{ mt: 3 }}>Contextual Form</Divider>
                            <Stack
                              spacing={2}
                              sx={{
                                background: "#f6f6f6",
                                p: 2,
                                px: 3,
                              }}
                            >
                              {group?.contextualForms?.map((cForm, index) => {
                                if (!cForm?.answer) return null;
                                return (
                                  <Card
                                    key={cForm?._id}
                                    elevation={0}
                                    sx={{
                                      background: "#f6f6f6",
                                    }}
                                  >
                                    <CardHeader
                                      title={
                                        <Typography
                                          variant="h5"
                                          component="div"
                                        >
                                          {index + 1}. {cForm?.title || ""}
                                          {cForm?.required && (
                                            <span
                                              style={{
                                                color: "red",
                                                marginInline: 3,
                                              }}
                                            >
                                              *
                                            </span>
                                          )}
                                        </Typography>
                                      }
                                      subheader={
                                        <Typography
                                          variant="subtitle1"
                                          sx={{ mx: 1.5 }}
                                        >
                                          {cForm?.description || ""}
                                        </Typography>
                                      }
                                    />
                                    <CardContent sx={{ py: 0, mx: 1.5 }}>
                                      <QuestionInputField
                                        question={cForm}
                                        handleFormInput={handleFormInput}
                                        formInput={formInput}
                                        setFormInput={setFormInput}
                                        disabled={true}
                                      />
                                    </CardContent>
                                  </Card>
                                );
                              })}
                            </Stack>
                          </Fragment>
                        )}
                    </Card>
                  ))}
                  <Stack spacing={2} my={2}>
                    {form?.misconductForms?.length > 0 &&
                      form?.misconductForms?.some(
                        (misForm) => misForm?.answer
                      ) && (
                        <Fragment>
                          <Divider sx={{ mt: 6 }}>Cyber Misconduct </Divider>
                          <Stack
                            spacing={2}
                            sx={{
                              py: 2,
                            }}
                          >
                            {form?.misconductForms?.map((misForm, index) => (
                              <Card key={misForm?._id}>
                                <CardHeader
                                  title={
                                    <Typography variant="h5" component="div">
                                      {index + 1}. {misForm?.title || ""}
                                      {misForm?.required && (
                                        <span
                                          style={{
                                            color: "red",
                                            marginInline: 3,
                                          }}
                                        >
                                          *
                                        </span>
                                      )}
                                    </Typography>
                                  }
                                  subheader={
                                    <Typography
                                      variant="subtitle1"
                                      sx={{ mx: 1.5 }}
                                    >
                                      {misForm?.description || ""}
                                    </Typography>
                                  }
                                />
                                <CardContent sx={{ py: 0, mx: 1.5 }}>
                                  <QuestionInputField
                                    question={misForm}
                                    handleFormInput={handleFormInput}
                                    formInput={formInput}
                                    setFormInput={setFormInput}
                                    disabled={true}
                                  />
                                </CardContent>
                              </Card>
                            ))}
                          </Stack>
                        </Fragment>
                      )}
                  </Stack>
                </>
              )}
            </Stack>
          </ReactPrint>
        );
      })()}
    </Fragment>
  );
}
