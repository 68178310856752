import {
  BookOutlined,
  TaskAlt,
  MilitaryTechOutlined,
  PlayCircle,
  OpenInNew,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  FormHelperText,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
// import React from 'react';
import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import ProgressCard from "src/components/ProgressCard";
import { IconCard } from "../CareerProtection/BuyCareerProtection";
import { GlobalContext } from "src/contexts/GlobalContext";
import axiosInstance from "src/utils/axiosInstance";
import { Confirm, Notify } from "notiflix";
import { calculateFinalScore } from "src/utils/helpers";
import { Link } from "react-router-dom";
import SuspenseLoader from "src/components/SuspenseLoader";

const modules = [
  {
    title: "Module 1: What is Ethics",
    url: "https://www.youtube.com/watch?v=UXfazYR5wPk",
    status: "default",
  },
  {
    title: "Module 2: On Campus Conduct",
    url: "https://youtu.be/EAeBbBEsuuY?si=pqrthC8Cma6ZJjth",
    status: "default",
  },
  {
    title: "Module 3: No Ragging",
    url: "https://youtu.be/_5Bip0YlrlI?si=XQrAkoTdxwQ-LdPM",
    status: "default",
  },
  {
    title: "Module 4: Respecting Teachers and Professors",
    url: "https://youtu.be/ywZPo8WgXYo?si=CZXX4_F0vv_ONOQK",
    status: "default",
  },
  {
    title: "Module 5: Plagiarism and Collusion",
    url: "https://youtu.be/O6qgN0hebQU?si=VlKAeRexWsjLB6eA",
    status: "default",
  },
  {
    title: "Module 6: Honest CVs",
    url: "https://youtu.be/QXyHh86IApI?si=WwEmic-ZtfsISFMn",
    status: "default",
  },
  {
    title: "Module 7: Respecting diversity and issues of Racism",
    url: "https://youtu.be/eF1MjnfWgl0?si=vj94iIXozJT8-S_c",
    status: "default",
  },
  {
    title: "Module 8: Gender Sensitivity",
    url: "https://youtu.be/mJlUuw7IWyE?si=ayhj9Ot9KQw90sVc",
    status: "default",
  },
  {
    title: "Module 9: Free Speech and Protest",
    url: "https://youtu.be/hDMo6Mc1rwM?si=vJ2seIhPG2_okTvy",
    status: "default",
  },
  {
    title: "Module 10: Data Privacy in Colleges Universities",
    url: "https://youtu.be/MT4CD1VpU6U?si=KzfbFY9gHHZPj_gA",
    status: "default",
  },
  {
    title: "Module 11: Substance Abuse",
    url: "https://youtu.be/WxVu962Ntgs?si=VEEWBpTzv-PN-M4v",
    status: "default",
  },
  {
    title: "Module 12: Social Media Best Practices",
    url: "https://youtu.be/6C1a2mDN0T4?si=eZXVVBk4kmxLZ9b3",
    status: "default",
  },
  {
    title: "Module 13: Ethics and Career",
    url: "https://youtu.be/I6B-j4BtS20?si=CDYrotod5QRTHPdc",
    status: "default",
  },
  {
    title: "Module 14: Ethics issues at workplace and How can Clean Exit help",
    url: "https://youtu.be/lAa2wommMyY?si=o-T9Yut4HYvCrLKc",
    status: "default",
  },
  {
    title: "Module 15: Benefit to students",
    url: "https://youtu.be/6CYly7im_nQ?si=7notQ4wLT2Kg3P6p",
    status: "default",
  },
];

function HonestCV() {
  const { currentUser } = useContext(GlobalContext);
  const theme = useTheme();
  const CHECKBOX_COLORS = {
    default: "default",
    progress: "primary",
    completed: "success",
  };
  const TEXT_COLORS = {
    default: "GrayText",
    progress: theme.palette.primary.main,
    completed: theme.palette.success.main,
  };
  const [state, setState] = useState(null);

  useEffect(() => {
    const db = localStorage.getItem("ethixfirst_cv_db" + currentUser?.userId);
    if (db) {
      const _db = JSON.parse(db);
      setState(_db);
    } else {
      setState({ modules, isSignedCommitment: false });
    }
  }, []);

  useEffect(() => {
    if (state) {
      localStorage.setItem(
        "ethixfirst_cv_db" + currentUser?.userId,
        JSON.stringify(state)
      );
    }
  }, [state]);

  const getProgress = (tracks) => {
    if (!tracks) {
      return 0;
    }
    return parseInt(
      ((tracks?.filter((e) => e.status === "completed")?.length || 0) /
        tracks?.length) *
        100
    );
  };

  const [exams, setExams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [highestScoreExam, setHighestScoreExam] = useState(null);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const honestCVExams = process.env.REACT_APP_HONEST_CV;
      const resp = await axiosInstance.get(`/ratings?type=exam`);
      if (resp.data) {
        const _exams = resp?.data?.data?.filter(
          (e) => e?.formId?._id === honestCVExams
        );
        setExams(_exams);
        // get highest score exam
        if (_exams?.length > 0) {
          const _highestScoreExam = _exams?.reduce((prev, current) =>
            prev?.score > current?.score ? prev : current
          );
          setHighestScoreExam(_highestScoreExam);
        }
      }
      setLoading(false);
    })();
  }, []);

  return loading ? (
    <SuspenseLoader />
  ) : (
    <Stack spacing={3} py={3}>
      <Typography variant="h1">Honest CV Dashboard</Typography>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <ProgressCard
              title={"Course Progress"}
              content={`${getProgress(state?.modules)}%`}
              caption={`Keep going, you're doing great!`}
              Icon={<BookOutlined color="primary" sx={{ fontSize: 28 }} />}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            {highestScoreExam ? (
              <>
                {(() => {
                  const result = calculateFinalScore(
                    {
                      score: highestScoreExam?.score,
                      weightage: highestScoreExam?.weightage,
                      passingPercentage: highestScoreExam?.passingPercentage,
                    },
                    "exam"
                  );
                  return (
                    <ProgressCard
                      title={"Exam Score"}
                      caption={[
                        result?.score > 90
                          ? "Excellent! You're a star!"
                          : result?.score >= highestScoreExam?.passingPercentage
                          ? "Great job! Keep it up!"
                          : result?.label === "Fail"
                          ? "Failed: But you can do better!"
                          : "Take Honest CV Exam to get certificate!",
                      ]}
                      content={`${result?.score || 0}%`}
                      Icon={
                        <MilitaryTechOutlined
                          color={result?.error || "primary"}
                          sx={{ fontSize: 28 }}
                        />
                      }
                    />
                  );
                })()}
              </>
            ) : (
              <ProgressCard
                title={"Exam Score"}
                content={"0%"}
                caption={`Take Exam to get score and certificate!`}
                Icon={
                  <MilitaryTechOutlined color="success" sx={{ fontSize: 28 }} />
                }
              />
            )}
          </Grid>
          <Grid item xs={12} lg={4}>
            {exams?.length > 0 ? (
              <>
                {(() => {
                  const result = calculateFinalScore(
                    {
                      score: highestScoreExam?.score,
                      weightage: highestScoreExam?.weightage,
                      passingPercentage: highestScoreExam?.passingPercentage,
                    },
                    "exam"
                  );
                  return (
                    <ProgressCard
                      title={
                        exams?.length > 0 &&
                        highestScoreExam?.certificate?.url ? (
                          <Button
                            size="small"
                            sx={{
                              fontSize: "1.2rem",
                            }}
                            component={Link}
                            to={highestScoreExam?.certificate?.url || "#"}
                          >
                            Certificate
                          </Button>
                        ) : (
                          ""
                        )
                      }
                      content={
                        exams?.length > 0 &&
                        highestScoreExam?.certificate?.url ? (
                          "Earned"
                        ) : (
                          <Link
                            to={`/exams/${process.env.REACT_APP_HONEST_CV}`}
                            style={{ textDecoration: "none" }}
                          >
                            <Typography variant="h3" color={"blue"}>
                              Retake Honest CV Exam
                              <OpenInNew
                                fontSize="small"
                                sx={{
                                  ml: 1,
                                }}
                              />
                            </Typography>
                          </Link>
                        )
                      }
                      caption={
                        exams?.length > 0 && highestScoreExam?.certificate?.url
                          ? "Congratulations! You've earned the certificate!"
                          : "Get certified!"
                      }
                      Icon={
                        <BookOutlined color="warning" sx={{ fontSize: 28 }} />
                      }
                    />
                  );
                })()}
              </>
            ) : (
              <Link
                to={`/exams/${process.env.REACT_APP_HONEST_CV}`}
                style={{ textDecoration: "none" }}
              >
                <ProgressCard
                  content={
                    <Typography variant="h3" color={"blue"}>
                      Take Honest CV Exam
                      <OpenInNew
                        fontSize="small"
                        sx={{
                          ml: 1,
                        }}
                      />
                    </Typography>
                  }
                  caption={"and get certificate!"}
                  Icon={<BookOutlined color="warning" sx={{ fontSize: 28 }} />}
                />
              </Link>
            )}
          </Grid>
        </Grid>
      </Box>
      <Card sx={{ p: 2.5 }}>
        <Typography variant="h2" mb={2} fontSize={"large"}>
          Course Outline
        </Typography>
        <List sx={{ p: 0 }}>
          {state?.modules?.map((module, index) => (
            <ListItem key={index} sx={{ px: 0 }}>
              <ListItemIcon sx={{ minWidth: 24, mr: 1 }}>
                <Checkbox
                  sx={{
                    p: 0,
                  }}
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<TaskAlt />}
                  checked={module.status === "completed"}
                  color={CHECKBOX_COLORS[module.status]}
                  onClick={() => {
                    // if (module?.opened) {
                    const data = JSON.parse(JSON.stringify(state?.modules));
                    data[index].status = "completed";
                    setState({ ...state, modules: data });
                    // }
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Box display={"flex"} alignItems={"center"}>
                    {module.title}
                    <ListItemIcon
                      onClick={() => {
                        const data = JSON.parse(JSON.stringify(state?.modules));
                        data[index].status = "progress";
                        data[index].opened = true;
                        setState({ ...state, modules: data });
                        window.open(module.url, "_blank");
                      }}
                      sx={{ minWidth: 24, ml: 1 }}
                    >
                      <PlayCircle
                        color="primary"
                        sx={{
                          fontSize: 28,
                          cursor: "pointer",
                          "&:hover": {
                            color: theme.palette.primary.dark,
                          },
                        }}
                      />
                    </ListItemIcon>
                  </Box>
                }
                primaryTypographyProps={{
                  sx: {
                    color: TEXT_COLORS[module.status],
                    fontSize: "Medium",
                    fontWeight: "500",
                  },
                }}
              />
            </ListItem>
          ))}
        </List>
      </Card>
      {!state?.isSignedCommitment ? (
        <Card sx={{ p: 2.5 }}>
          <Typography variant="h2" mb={2} fontSize={"large"}>
            Your Honest CV Commitment
          </Typography>
          <Typography
            variant="body1"
            component="div"
            gutterBottom
            sx={{
              fontSize: "Medium",
              mb: 2,
            }}
          >
            "I, {currentUser?.firstName} {currentUser?.lastName || ""}, commit
            to upholding the principles of honesty and integrity in my
            professional representation. I will strive for transparency,
            accuracy, and ethical conduct in all aspects of my career journey."
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ borderRadius: 0.5 }}
            onClick={() => {
              Confirm.show(
                "Confirmation",
                "Are you sure you want to sign the commitment?",
                "Confirm",
                "Cancel",
                () => setState({ ...state, isSignedCommitment: true }),
                () => {},
                {
                  width: "500px",
                  messageMaxLength: 1000,
                }
              );
            }}
            disabled={
              exams?.length === 0 ||
              highestScoreExam?.score < highestScoreExam?.passingPercentage
            }
          >
            Sign Commitment
          </Button>
          {exams?.length === 0 &&
            highestScoreExam?.score < highestScoreExam?.passingPercentage && (
              <FormHelperText>
                You must poss the Honest CV Exam to sign the commitment
              </FormHelperText>
            )}
        </Card>
      ) : (
        <IconCard
          title={`🎉 Congratulations! You've Earned the Honest CV Commitment Badge! 🎉`}
          Icon={
            <Avatar
              src={"/static/images/placeholders/cup.png"}
              variant="square"
              sx={{
                ".MuiAvatar-img": {
                  objectFit: "contain",
                },
                width: 44,
                height: 44,
              }}
            />
          }
          desc={
            "Keep up the excellent work, and continue to set a positive example in the professional community!"
          }
        />
      )}
    </Stack>
  );
}

export default HonestCV;
