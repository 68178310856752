import { AddTwoTone, Upload } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { Notify } from "notiflix";
import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ReactPrint } from "src/components/ReactToPrint";
import { GlobalContext } from "src/contexts/GlobalContext";
import { ProfileContext } from "src/contexts/ProfileContext";
import axiosInstance from "src/utils/axiosInstance";
import { fDate } from "src/utils/date-fns";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

function RatingConsentForm({ documents, handleSkip }) {
  const inputFile = useRef(null);
  const { profile } = useContext(ProfileContext);
  const { currentUser } = useContext(GlobalContext);
  const [signatureImage, setSignatureImage] = useState(null);
  const [consent, setConsent] = useState(false);
  const [isWait, setIsWait] = useState(false);
  const [onlyPrint, setOnlyPrint] = useState(true);
  const [consentData, setConsentData] = useState(null);
  const [aadhaarImage, setAadhaarImage] = useState(null);
  const [base64Image, setBase64Image] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dob, setDob] = useState(null);

  useEffect(() => {
    const loadImageAsBase64 = async () => {
      const imagePath = "/static/images/logo/logo.png";
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "/" + imagePath
      );
      const blob = await response.blob();

      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        setBase64Image(base64data);
      };

      reader.readAsDataURL(blob);
    };

    loadImageAsBase64();
  }, []);
  useEffect(() => {
    if (documents) {
      const consent = documents?.data?.find(
        (doc) => doc?.category === "consents"
      );
      if (consent) {
        setConsentData(consent);
      }
    }
  }, [documents]);

  const [print, setPrint] = useState(false);
  const handleClickPrint = (action) => {
    setIsSubmitting(true);
    setOnlyPrint(action !== "submit");
    setPrint(true);
    setTimeout(() => {
      setPrint(false);
      setIsSubmitting(false);
    }, 1000);
  };

  const handleSaveForm = async (pdfData) => {
    try {
      if (!dob) {
        Notify.failure("Please select your DOB.");
        return;
      }
      if (!consent) {
        Notify.failure("Please accept the terms and conditions.");
        return;
      }

      setIsSubmitting(true);
      setIsWait(true);

      const formData = new FormData();
      const htmlContent = pdfData?.innerHTML;
      const htmlBlob = new Blob([htmlContent], { type: "text/html" });

      formData.append("document", htmlBlob);
      const resp = await axiosInstance.post(
        "/documents/rating-consent",
        formData
      );

      if (resp?.status === 201) {
        window.location.reload();
        setIsSubmitting(false);
      }

      setIsWait(false);
    } catch (error) {
      setIsSubmitting(false);
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.data?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  const SignatureBox = () => {
    return (
      <Grid container>
        <Grid item xs={9}>
          <Box id="hide-on-print">
            {!isSubmitting && (
              <Stack direction={"row"} alignItems={"center"} spacing={2} mb={2}>
                <Button
                  sx={{
                    color: "primary.main",
                    border: "1px dashed",
                    borderColor: "primary.main",
                    "&:hover": {
                      color: "primary.dark",
                      borderColor: "primary.dark",
                    },
                    p: 2,
                    whiteSpace: "nowrap",
                  }}
                  onClick={() => inputFile.current.click()}
                  startIcon={<AddTwoTone />}
                >
                  Upload Signature
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      const reader = new FileReader();
                      reader.onloadend = () => {
                        setSignatureImage(reader.result);
                      };
                      reader.readAsDataURL(file);
                    }}
                    multiple={false}
                    style={{ display: "none" }}
                    ref={inputFile}
                  />
                </Button>

                {(!consentData || !profile?.ratingConsented) && (
                  <Button
                    component="label"
                    sx={{
                      whiteSpace: "nowrap",
                      color: "primary.main",
                      border: "1px dashed",
                      borderColor: "primary.main",
                      "&:hover": {
                        color: "primary.dark",
                        borderColor: "primary.dark",
                      },
                      p: 2,
                      whiteSpace: "nowrap",
                    }}
                    startIcon={<Upload />}
                  >
                    Upload Govt ID (format: png, jpg, jpeg)
                    <VisuallyHiddenInput
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        const reader = new FileReader();
                        reader.onloadend = () => {
                          setAadhaarImage(reader.result);
                        };
                        reader.readAsDataURL(file);
                      }}
                    />
                  </Button>
                )}
              </Stack>
            )}
          </Box>
          <Stack sx={{ mt: 2, width: "30%" }}>
            {signatureImage && (
              <img
                src={signatureImage}
                alt="signature"
                style={{ width: "100px", marginRight: "20px" }}
              />
            )}
            <Stack direction={"row"} alignItems={"center"} mb={1}>
              <Typography variant="caption" sx={{ mr: 2 }}>
                Signature
              </Typography>
              <Divider style={{ width: "100%" }} />
            </Stack>
            <Typography
              variant="h3"
              sx={{
                whiteSpace: "nowrap",
                overflow: "visible",
                textOverflow: "clip",
              }}
            >
              {profile?.firstName || "-"} {profile?.lastName || ""}
            </Typography>
            <Stack direction={"row"} alignItems={"center"} mb={2}>
              <Typography
                variant="caption"
                sx={{ mr: 2, whiteSpace: "nowrap" }}
              >
                Full Name
              </Typography>
              <Divider style={{ width: "100%" }} />
            </Stack>
            <Typography variant="h4" noWrap>
              {profile?.cleanExitUserId || "-"}
            </Typography>
            <Stack direction={"row"} alignItems={"center"} mb={2}>
              <Typography
                variant="caption"
                sx={{ mr: 2, whiteSpace: "nowrap" }}
              >
                EthixFirst ID
              </Typography>
              <Divider style={{ width: "85%" }} />
            </Stack>

            <Stack direction={"row"} alignItems={"center"} spacing={4}>
              {dob && (
                <Typography variant="h4">{fDate(dob, "dd/MM/yyyy")}</Typography>
              )}
              <Box id="hide-on-print">
                {!isSubmitting && (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      sx={{ minWidth: "200px" }}
                      label="Select DOB *"
                      value={dob}
                      onChange={(newValue) => {
                        setDob(newValue);
                      }}
                      format="dd/MM/yyyy"
                      maxDate={new Date()}
                    />
                  </LocalizationProvider>
                )}
              </Box>
            </Stack>
            <Stack direction={"row"} alignItems={"center"}>
              <Typography
                variant="caption"
                sx={{ mr: 2, whiteSpace: "nowrap" }}
              >
                Date of Birth (dd/mm/yyyy)
              </Typography>
              <Divider style={{ width: "40%" }} />
            </Stack>
          </Stack>
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {profile?.profileImage && (
            <Avatar
              src={
                process.env.REACT_APP_DO_SPACE_URL +
                profile?.profileImage +
                `?${Math.random()}`
              }
              alt={profile?.firstName + " " + (profile?.lastName || "")}
              sx={{
                mx: "auto",
                width: 190,
                height: 190,
              }}
            />
          )}
        </Grid>
      </Grid>
    );
  };

  return consentData && consentData?.url && profile?.ratingConsented ? (
    <Card
      sx={{
        border: 1,
        borderColor: "grey.400",
      }}
    >
      <CardContent>
        <embed
          width="100%"
          height={"1400px"}
          src={
            process.env.REACT_APP_DO_SPACE_URL +
              consentData?.url +
              `?${new Date().getTime()}` || ""
          }
          alt="consent"
        />
      </CardContent>
    </Card>
  ) : (
    <Box sx={{ my: 2 }}>
      <Box display="flex" alignItems="center" justifyContent="flex-end" my={2}>
        {/* <Button
          variant="outlined"
          onClick={() => handleClickPrint("print")}
          size="small"
        >
          Download as PDF
        </Button> */}
      </Box>

      <Card
        sx={{
          border: 1,
          borderColor: "grey.400",
          py: 6,
          px: 3,
          background: "white",
          color: "black",
        }}
      >
        <ReactPrint
          print={print}
          getPrintData={(data) => {
            if (!onlyPrint) {
              handleSaveForm(data);
            }
          }}
          getData={!onlyPrint}
        >
          <Container id="consent-section-print" maxWidth="md">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{ my: 2 }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                style={{
                  width: "max-content",
                }}
              >
                <img src={base64Image} alt={""} style={{ width: "52px" }} />
                <Typography variant="h2" ml={1} color={"black"}>
                  EthixFirst
                </Typography>
              </Box>
              <Box textAlign={"right"}>
                <Typography variant="h3" color={"black"}>
                  Rating Consent Form
                </Typography>
              </Box>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h4">
              Undertaking and Consent for EthixFirst Ethics at Workplace Rating
              Service
            </Typography>
            <br />
            <br />
            <Typography paragraph sx={{ fontSize: 16 }}>
              I as an applicant for EthixFirst rating service, authorize Clean
              Exit to collect any data relating to me compliance /
              non-compliance of any rules, policies, byelaws, processes, laws
              and otherwise, during the period of study or atier the completion
              of my study, including internship / probation and during the
              entire course of my employment or service (current or future), as
              applicable, (or for a period which makes such noncompliance
              reasonably recordable) (the “Data”).
            </Typography>
            <br />
            <Typography paragraph sx={{ fontSize: 16 }}>
              Such Data is being recorded and processed by EthixFirst and its
              partners, including external third- party agencies. I shall abide
              by any rules and regulations set by EthixFirst in this behalf. I
              hereby voluntarily authorize EthixFirst to conduct any background
              checks / investigations and also authorize the use of any Data
              with respect to my working at any Organization, EthixFirst and any
              third-party agency in collecting, analyzing, assimilating,
              recording and sharing this information with any third party.
            </Typography>
            <br />
            <Typography paragraph sx={{ fontSize: 16 }}>
              I agree and understand that recording or processing of any Data or
              record (negative or otherwise) of my compliance or non-compliance
              and dealing with any data pertaining to it (including sharing the
              Data with any third party and / or publishing by EthixFirst and
              third-party agency) is strictly in the spirit of rating the
              workforce for their ethical conduct in the industry / industries
              and does not amount to libel. I agree that I shall not bring any
              suit or cause of action against EthixFirst or the concerned
              third-party agency and indemnify EthixFirst and any third-party
              agencies from and against any and all claims, demands, damages,
              injuries, expenses and liability arising from my acts or omissions
              pertaining to the Data.
            </Typography>
            <br />
            <Typography paragraph sx={{ fontSize: 16 }}>
              I agree that EthixFirst will hold and continue to maintain the
              previously collected Data, in the event of termination of my
              registration / account. I also agree to follow the laws, rules,
              regulations, guidelines set up by the EthixFirst and in case of
              any breach of such laws, rules, regulations, and guidelines, I
              shall indemnify and hold EthixFirst harmless from any damages or
              claims.
            </Typography>
            <br />
            <Typography paragraph sx={{ fontSize: 16 }}>
              The information provided in this consent and undertaking, and any
              attachments thereto is true and complete, to the best of my
              knowledge. I understand that any falsification or omission of
              information may atiract appropriate action / rating by Ethix
              Cards.
            </Typography>
            {currentUser?.userId === profile?._id && <SignatureBox />}
            {aadhaarImage && (
              <Box my={4}>
                <img
                  src={aadhaarImage}
                  alt="aadhaar"
                  style={{ width: "100%" }}
                />
              </Box>
            )}
          </Container>
        </ReactPrint>
      </Card>
      {currentUser?.userId === profile?._id &&
        (!consentData || !profile?.ratingConsented) && (
          <Box mb={6}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              mt={2}
              mb={10}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    name="terms"
                    color="primary"
                    sx={{ width: "max-content" }}
                  />
                }
                checked={consent}
                onChange={(e) => setConsent(e.target.checked)}
                sx={{
                  display: "flex",
                  alignContent: "flex-start",
                }}
                label={
                  <>
                    <Typography variant="body2">
                      I accept the{" "}
                      <Link
                        component="a"
                        href="#"
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        terms and conditions
                      </Link>
                      .
                    </Typography>
                  </>
                }
              />
              <Stack spacing={2} direction={"row"}>
                {handleSkip && (
                  <Button onClick={handleSkip} sx={{ whiteSpace: "nowrap" }}>
                    Skip for now
                  </Button>
                )}
                <LoadingButton
                  variant="contained"
                  onClick={() => handleClickPrint("submit")}
                  loading={isWait}
                  disabled={!consent || !dob}
                >
                  Submit Now
                </LoadingButton>
              </Stack>
            </Stack>
          </Box>
        )}
    </Box>
  );
}

export default RatingConsentForm;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
